import React from "react"
import infoStyle from "./city.module.scss"
import heroImg from "../images/cuidadesHero.jpg"
import heroWithTitleStyles from "../components/publicaciones/heroWithTitle.module.scss"
import Layout from '../components/layout/layout'
import iconPerson from "../images/15.png"
import iconCalendar from "../images/16.png"
import iconClock from "../images/17.png"
import iconSheet from "../images/18.png"
import iconPig from "../images/19.png"
import iconPin from "../images/20.png"
import iconPhone from "../images/22.png"
import iconEmail from "../images/21.png"
import { graphql } from "gatsby"

import { Link } from "gatsby"

export const query = graphql`
  query($slug: String!) {
    allAirtable(
      filter: { table: { eq: "Cuidades" }, data: { Cuidad: { eq: $slug } } }
    ) {
      edges {
        node {
          data {
            Name
            data
            data1
            data2
            Cuidad

            img {
              thumbnails {
                full {
                  url
                }
              }
            }
            link_horarios {
              data {
                data
                data2
                data3
                data4
              }
            }
            link_contenido {
              data {
                data1
                data2
                data3
                data4
                data5
                data6
                data7
              }
            }
          }
        }
      }
    }
  }
`

const infoCuidad = props => {
  let data = {
    courseContent: [],
    courseDate: "",
    courseDuration: "",
    courseGrups: [],
    courseInscription: "",
    couseWeeklyPayment: "",
    location: "",
    tel: "",
    email: "",
  }
  Object.values(
    props.data.allAirtable.edges.filter(
      node => node.node.data.Name === "contenido"
    )[0].node.data.link_contenido[0].data
  ).forEach(value => {
    if (value != null) {
      data.courseContent.push(value)
    }
  })
  data.courseDate = props.data.allAirtable.edges.filter(
    node => node.node.data.Name === "fecha"
  )[0].node.data.data
  data.courseDuration = props.data.allAirtable.edges.filter(
    node => node.node.data.Name === "duracion"
  )[0].node.data.data
  data.courseInscription = props.data.allAirtable.edges.filter(
    node => node.node.data.Name === "costo_de_inscripcion"
  )[0].node.data.data
  data.couseWeeklyPayment = props.data.allAirtable.edges.filter(
    node => node.node.data.Name === "costo_semanal"
  )[0].node.data.data
  data.location = props.data.allAirtable.edges.filter(
    node => node.node.data.Name === "lugar"
  )[0].node.data.data
  data.tel = props.data.allAirtable.edges.filter(
    node => node.node.data.Name === "tel"
  )[0].node.data.data
  data.email = props.data.allAirtable.edges.filter(
    node => node.node.data.Name === "email"
  )[0].node.data.data

  props.data.allAirtable.edges
    .filter(node => node.node.data.Name === "grupo")
    .map(group => {
      let schedule = []
      Object.values(group.node.data.link_horarios[0].data).forEach(value => {
        if (value != null) {
          schedule.push(value)
        }
      })
      data.courseGrups.push({
        name: group.node.data.data,
        schedule: schedule,
        percentage: parseInt(group.node.data.data2),
      })
    })
  let heroWithTitle = {
    title: props.data.allAirtable.edges[0].node.data.Cuidad,
    img: props.data.allAirtable.edges.filter(
      node => node.node.data.Name === "img"
    )[0].node.data.img[0].thumbnails.full.url,
    type: props.data.allAirtable.edges.filter(
      node => node.node.data.Name === "tipo"
    )[0].node.data.data,
  }
  return (
    <Layout style={{ height: "fit-content", overflow: "hidden" }}>
      <div>
        <div className={heroWithTitleStyles.container}>
          <div className={heroWithTitleStyles.imgContainer}>
            <img
              className={heroWithTitleStyles.img}
              src={heroWithTitle.img}
              alt="heroImg"
            />
          </div>
          <div className={heroWithTitleStyles.titleContainer}>
            <h1 className={heroWithTitleStyles.title}>{heroWithTitle.title}</h1>
          </div>
        </div>
      </div>

      <div className={infoStyle.imgContainer}>
        <img src={heroImg} className={infoStyle.img} alt="heroImg" />
        <div className={infoStyle.courseTypeMainContainer}>
          <div className={infoStyle.courseTypeContainer}>
            <h1 className={infoStyle.courseType}>{heroWithTitle.type}</h1>
          </div>
        </div>
      </div>
      <div className={infoStyle.container}>
        <div className={infoStyle.mainTitleContainer}>
          {" "}
          Información del Curso
        </div>
        <div className={infoStyle.whiteContainer}>
          <div className={infoStyle.mainTextContainer}>
            <div className={infoStyle.descriptionMainContainer}>
              <img src={iconPerson} className={infoStyle.icon} alt="icon" />
              <div />
              <div className={infoStyle.descriptionContainer}>
                <h2 className={infoStyle.titleDescription}>
                  {" "}
                  Incluye el Curso{" "}
                </h2>
                <ul className={infoStyle.ul}>
                  {data.courseContent.map(content => {
                    return (
                      <li className={infoStyle.li}>
                        <p className={infoStyle.p}>{content}</p>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>

            <div className={infoStyle.descriptionMainContainer}>
              <img src={iconCalendar} className={infoStyle.icon} alt="icon" />
              <div />
              <div className={infoStyle.descriptionContainer}>
                <h2 className={infoStyle.titleDescription}>
                  {" "}
                  Inicio de Curso{" "}
                </h2>
                <p className={infoStyle.descrition}>{data.courseDate}</p>
              </div>
            </div>

            <div className={infoStyle.descriptionMainContainer}>
              <img src={iconClock} className={infoStyle.icon} alt="icon" />
              <div />
              <div className={infoStyle.descriptionContainer}>
                <h2 className={infoStyle.titleDescription}>
                  {" "}
                  Duración de Curso{" "}
                </h2>
                <p className={infoStyle.descrition}>{data.courseDuration}</p>
              </div>
            </div>

            <div className={infoStyle.descriptionMainContainer}>
              <img src={iconSheet} className={infoStyle.icon} alt="icon" />
              <div />
              <div className={infoStyle.descriptionContainer}>
                <h2 className={infoStyle.titleDescription}>
                  {" "}
                  Horarios y grupos disponibles{" "}
                </h2>
                {data.courseGrups.map(grup => {
                  let temp = grup.schedule.map(sche => {
                    return (
                      <li className={infoStyle.li}>
                        <p className={infoStyle.p}>{sche}</p>
                      </li>
                    )
                  })
                  let lenghtBar = "" + grup.percentage + "%"
                  return (
                    <div>
                      <ul>{temp}</ul>
                      <div className={infoStyle.sliderMainContainer}>
                        <div className={infoStyle.sliderContainer}>
                          <div
                            className={infoStyle.slider}
                            style={{ width: lenghtBar }}
                          >
                            <p className={infoStyle.progressNumLeft}>
                              {grup.percentage}%
                            </p>
                          </div>
                        </div>
                        <img
                          src={iconPerson}
                          className={infoStyle.iconSlider}
                          alt="icon"
                        />
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>

            <div className={infoStyle.descriptionMainContainer}>
              <div />
              <div />
              <h3 className={infoStyle.sideText}>10 horas por semana</h3>
            </div>

            <div className={infoStyle.descriptionMainContainerSecondTitle}>
              <p className={infoStyle.secondTitle}>Finaliza</p>
              <div />
              <p className={infoStyle.pSecond}>
                De uno a dos días antes del examen de admisión
              </p>
            </div>
            <hr className={infoStyle.hr} />

            <div className={infoStyle.descriptionMainContainer}>
              <img src={iconPig} className={infoStyle.icon} alt="icon" />
              <div />
              <div className={infoStyle.descriptionContainer}>
                <h2 className={infoStyle.titleDescription}> Inversión </h2>
                <p className={infoStyle.descrition}>
                  <span className={infoStyle.span}>Inscripción: </span>
                  {data.courseInscription}
                </p>
                <p className={infoStyle.descrition}>
                  <span className={infoStyle.span}>Pago Semanal: </span>
                  {data.couseWeeklyPayment}
                </p>
              </div>
            </div>

            <div className={infoStyle.descriptionMainContainer}>
              <img src={iconPin} className={infoStyle.icon} alt="icon" />
              <div />
              <div className={infoStyle.descriptionContainer}>
                <h2 className={infoStyle.titleDescription}> Ubicación </h2>
                <p className={infoStyle.descrition}>{data.location}</p>
              </div>
            </div>

            <Link style={{ textDecoration: "none" }} to="inscripciones">
              <div className={infoStyle.btnInscripcion}>Inscripción</div>
            </Link>
          </div>
        </div>

        <div className={infoStyle.whiteContainer}>
          <div className={infoStyle.mainTextContainer}>
            <div className={infoStyle.descriptionContactMainContainer}>
              <div />
              <div />
              <h1 className={infoStyle.titleDescription}>Contacto</h1>
            </div>

            <div className={infoStyle.descriptionContactMainContainer}>
              <img src={iconPhone} className={infoStyle.icon} alt="icon" />
              <div />
              <p className={infoStyle.contactDescription}> {data.tel}</p>
            </div>

            <div className={infoStyle.descriptionContactMainContainer}>
              <img src={iconEmail} className={infoStyle.icon} alt="icon" />
              <div />
              <p className={infoStyle.contactDescription}> {data.email} </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default infoCuidad
